<template>
    <v-dialog persistent v-model="dialog" :width=myWidth>
        <v-sheet class="kc-add-csv-process-dialog mx-auto pa-5" elevation="12" width="100%">
            <!-- <v-row style="background-color: #dddddd;
    height: 39px;
    margin-top: -20px;
    margin-left: -20px;
    margin-right: -20px;">
                <v-col style="padding-top: 7px;
    padding-bottom: 2px; padding-left: 20px;
    font-size: 1.1em;" class="d-flex" cols="5">
                    <label>Choose a task</label>
                </v-col>
            </v-row> -->
            <v-row>
                <v-col class="d-flex" cols="6">
                    <v-select hide-details v-model="currentProcess" item-disabled="customDisabled" :items="effectiveCsvProcessList" :readonly="lock" :label=taskLabel dense></v-select>
                    <!-- {{headerHasID()}} -->
                </v-col>
            </v-row>
            <v-row v-if="currentProcess==''" class="justify-end">
                    <v-btn color="blue darken-1" text @click="closeClick()">
                        Close
                    </v-btn>
            </v-row>
            <v-sheet v-if="currentProcess == 'Select Column'">
                <v-form :disabled=readOnlyMode ref="selectForm" v-model="isSelectFormValid">
                <v-row>
                    <v-col class="py-0" dense cols="4">
                        <v-checkbox color="rgba(0,0,0,.54)" hide-details class="shrink mr-0 mt-0" v-model="chkAddHeader" label="Rename Header"></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2" dense cols="12">
                        <span class="py-0">Please select column</span>
                    </v-col>
                </v-row>            
                <v-row>
                    <v-sheet width="100%">
                    <draggable v-model="reorderHeaders" group="headers" @start="drag=true" @end="drag=false" handle=".handle">
                    <v-col dense pa-0 v-for="item in reorderHeaders" :key="item.name" class="d-flex" cols="12" sm="12">
                        <v-row>
                            <v-col class="py-0" cols="4">
                                <v-checkbox color="rgba(0,0,0,.54)" hide-details class="mt-0 shrink" v-model="item.select" :label=item.name >
                                </v-checkbox>
                            </v-col>
                            <v-col v-if="chkAddHeader == true && item.select == true" class="py-0" cols="4">
                                <v-text-field hide-details v-model="item.rename" :rules="[renameRules.nameNotExist(reorderHeaders,item)]" class="py-1 shrink" label="as"></v-text-field>
                            </v-col>
                            <!-- <v-col v-if="chkAddHeader == true && item.select == false" class="py-0" cols="4">
                            </v-col> -->
                            <v-col v-if="item.select == true" class="py-1" cols="2">
                                <v-icon style="cursor: grab;" class="handle">unfold_more</v-icon>
                            </v-col>                            
                        </v-row>
                    </v-col>
                    </draggable>
                    </v-sheet>
                </v-row>
                <!-- {{reorderHeaders}} -->
                <v-row class="justify-end">
                        <v-btn color="blue darken-1" text @click="closeClick()">
                            Close
                        </v-btn>
                        <v-btn v-if="readOnlyMode==false && selectColumnCount() > 0 && isSelectFormValid" color="blue darken-1" text  @click="saveSelectColumn()">
                            Save
                        </v-btn>
                </v-row>
                </v-form>
            </v-sheet>
            <v-sheet v-if="currentProcess == 'Transform Column'">
                <v-form :disabled=readOnlyMode ref="formTransformColumn" v-model="isTransformColumnValid">
                <v-row>
                    <v-col col=6>
                        <v-select :rules="transformColumnNameRules" hide-details="auto" class="py-1 shrink"
                            v-model="transformColumn.column"
                            :items="headers"
                            label="Column"
                        >
                            <!-- <template slot="prepend"><span class="required">*</span></template> -->
                        </v-select>
                    </v-col>
                    <v-col col=6>
                        <v-text-field :disabled="transformColumn.column==''" @click:append="helpClick(1)" _append-icon="help" :rules="transFormValueRules" hide-details="auto" v-model="transformColumn.value" class="py-1 shrink" label="Value">
                        </v-text-field>
                        <!-- <div>
                            <v-checkbox color="rgba(0,0,0,.54)" hide-details class="shrink mr-0 mt-0"
                                v-model="transformColumn.chkEvalMode" label="Eval mode"></v-checkbox>
                        </div> -->

                        <!-- <span style="background-color: #FFEBEE;">{{transformColumn.err}}</span> -->
                    </v-col>
                </v-row>
                <v-row style="margin-top: -27px;" class="px-4">
                    <!-- <v-col class="py-0" col=12>
                        <v-text-field outlined hide-details :readonly=true
                            v-model="testTransformColumnValue" class="py-1 shrink" label="Preview Render Value"></v-text-field>                
                    </v-col> -->
                    <div style="transform: translateY(16px) translateX(-17px) scale(.75);position: relative;background-color: white;padding: 0 10px;">Preview Render Value</div>
                    <v-col style="width: 100%;min-height: 40px;font-size: 0.9em;overflow-y: auto; white-space: nowrap;border-radius: 4px; border: solid 1px gray;" class="py-2 mx-0 my-1" cols=12>
                        <div>{{testTransformColumnValue}}</div>
                    </v-col>                            
                </v-row>                  
               <v-row>
                    <v-col col=6>
                         <v-checkbox color="rgba(0,0,0,.54)" hide-details class="mt-0 shrink" v-model="transformColumn.ignoreEmpty" label="Ignore Empty">
                        </v-checkbox>
                    </v-col>
                    <v-col v-if="showHelp[1] == true" style="font-size:0.75rem;" col=6>
                        <v-sheet v-if="transformColumn.chkEvalMode==true">
                        <span class="font-weight-bold">Syntax</span><br>
                        - $ or x -> current column data<br>
                        - support standard javascript expression include string interpolation<br>
                        <span class="font-weight-bold">Example</span><br>
                        - "$"+x  or `$${x}`  -> prepend $ with current column<br>
                        - $.toUpperCase() -> uppercase current column <br>
                        </v-sheet>
                        <v-sheet v-if="transformColumn.chkEvalMode==false">
                            <span class="font-weight-bold">Syntax</span><br>
                            - {} -> data from current column<br>
                            <span class="font-weight-bold">Example</span><br>
                            - {}.jpg -> append data from current column with .jpg <br>
                        </v-sheet>
                    </v-col>
                </v-row>                
                <v-row class="justify-end">
                        <v-btn color="blue darken-1" text @click="closeClick()">
                            Close
                        </v-btn>
                        <v-btn v-if="readOnlyMode==false && isTransformColumnValid==true" color="blue darken-1" text @click="saveTransformColumn()">
                            Save
                        </v-btn>
                </v-row>
                </v-form>
            </v-sheet>
            <v-sheet v-if="currentProcess == 'Calculated Column'">
                <v-form :disabled=readOnlyMode ref="formCalculatedColumn" v-model="isCalculatedColumnValid">
                <v-row>
                    <v-col cols=6>
                        <v-text-field ref="calculatedColumnName" v-model="calculatedColumn.name" :rules="nameRules" class="py-1 shrink" label="Name">
                            <!-- <template slot="prepend"><span class="required">*</span></template> -->
                        </v-text-field>
                    </v-col>
                    <v-col cols=6>
                        <v-text-field  :disabled="calculatedColumn.name==''" ref="calculatedColumnValue" :rules="valueRules" hide-details="auto" @click:append="helpClick(2)" _append-icon="help" v-model="calculatedColumn.value" class="py-1 shrink" label="Value">
                        </v-text-field>
                        <!--<div>
                         <v-checkbox color="rgba(0,0,0,.54)" hide-details class="shrink mr-0 mt-0"
                            v-model="calculatedColumn.chkEvalMode" label="Eval mode"></v-checkbox>
                        </div> -->
                        <!-- <span style="background-color: #FFEBEE;">{{calculatedColumn.err}}</span> -->
                    </v-col>
                </v-row>
                <v-row style="margin-top: -27px;" class="px-4">
                    <div style="transform: translateY(16px) translateX(-17px) scale(.75);position: relative;background-color: white;padding: 0 10px;">Preview Render Value</div>
                    <v-col style="width: 100%;min-height: 36px;font-size: 0.9em;overflow-y: auto; white-space: nowrap;border-radius: 4px; border: solid 1px gray;" class="py-1 mx-0 my-1" cols=12>

                        <div>{{testCalculatedColumnValue}}</div>
                    </v-col>                            

                </v-row>                     
                <v-row v-if="showHelp[2] == true" >
                    <v-col col=6>
                        <v-select hide-details class="py-1 shrink"
                            v-model="calculatedColumnSelectorValue"
                            :items="headers"
                            label="Column selector"
                            disable-lookup
                            dense
                        >
                        </v-select>
                    </v-col>
                    <v-col v-if="calculatedColumn.chkEvalMode==true" style="font-size:0.75rem;" cols=6>
                        <v-sheet>
                            <span class="font-weight-bold">Syntax</span><br>
                            - $n -> column n data start from 1, Ex $2 as column2 <br>
                            - support standard javascript expression include string interpolation<br>
                            <span class="font-weight-bold">Example</span><br>
                            - "$"+$1 or `${$1}` -> prepend $ with column1<br>
                            - $1.toUpperCase() -> uppercase column1 <br>
                            - $1 + " " + $2 -> concat column 1 and 2 <br>
                        </v-sheet>
                    </v-col>
                    <v-col v-if="calculatedColumn.chkEvalMode==false" style="font-size:0.75rem;" cols=6>
                        <v-sheet>
                            <span class="font-weight-bold">Syntax</span><br>
                            - {header} -> data from that header column<br>
                            <span class="font-weight-bold">Example</span><br>
                            - {purl}.jpg -> append data from purl column with .jpg <br>
                        </v-sheet>
                    </v-col>           
                </v-row>
                <v-row class="pt-2 mt-2">
                    <v-col class="py-0" col=6>
                        <v-checkbox color="rgba(0,0,0,.54)" hide-details class="mt-0 shrink" v-model="calculatedColumn.ignoreEmpty" label="Ignore Empty">
                        </v-checkbox>
                    </v-col>
                    <!-- <v-col class="py-0" col=6>
                        <v-text-field outlined hide-details :readonly=true
                            v-model="testCalculatedColumnValue" class="py-1 shrink" label="Preview Render Value"></v-text-field>                
                    </v-col> -->
                </v-row>                

                <v-row class="justify-end">
                    <v-btn color="blue darken-1" text @click="closeClick()">
                        Close
                    </v-btn>
                    <v-btn v-if="readOnlyMode==false && isCalculatedColumnValid == true" color="blue darken-1" text  @click="saveCalculatedColumn()">
                        Save
                    </v-btn>
                </v-row>
                </v-form>
            </v-sheet>     
            <v-sheet v-if="currentProcess == 'Sort By Column'">
                <v-row>
                    <v-col class="py-2" dense cols="12">
                        <span class="py-0">Please select column</span>
                    </v-col>
                </v-row>            
                <v-row>
                    <v-sheet width="100%">
                    <draggable v-model="orderByHeaders" group="orderByHeaders" @start="drag=true" @end="drag=false" handle=".handle">
                    <v-col dense pa-0 v-for="item in orderByHeaders" :key="item.name" class="d-flex" cols="12" sm="12">
                        <v-row>
                            <v-col class="py-0" cols="4">
                                <v-checkbox color="rgba(0,0,0,.54)" hide-details class="mt-0 shrink" v-model="item.select" :label=item.name >
                                </v-checkbox>
                            </v-col>
                            <v-col v-if="item.select == true" class="py-0" cols="2">
                                <v-switch class="mt-0 shrink"
                                    v-model="item.ascending"
                                    :label="item.ascending ? 'A -> Z' : 'Z -> A'"
                                ></v-switch>
                            </v-col>
                            <v-col v-if="item.select == true" class="py-0" cols="2">
                                <v-icon class="handle">drag_handle</v-icon>
                            </v-col>   
                        </v-row>
                    </v-col>
                    </draggable>
                    </v-sheet>
                </v-row>
                <v-row class="justify-end">
                        <v-btn color="blue darken-1" text @click="closeClick()">
                            Close
                        </v-btn>
                        <v-btn v-if="readOnlyMode==false && sortByColumnCount()>0" color="blue darken-1" text  @click="saveSortByColumn()">
                            Save
                        </v-btn>
                </v-row>
            </v-sheet>  
            <v-sheet v-if="currentProcess == 'QRCode'">
                <!-- <KCQRCodeConfig :value="qrCodeConfig" @commit="qrcodeCommit"></KCQRCodeConfig> -->
                <KCQRCodeConfig v-model="qrCodeConfig" :headers="headers" :sampleData="sampleData" :readOnlyMode=readOnlyMode></KCQRCodeConfig>
                <!-- {{qrCodeConfig}} -->
                <v-row class="justify-end">
                        <v-btn color="blue darken-1" text @click="closeClick()">
                            Close
                        </v-btn>
                        <v-btn v-if="shouldShowQrcodeSaveButton" color="blue darken-1" text  @click="saveQRCodeConfig()">
                            Save
                        </v-btn>
                </v-row>
            </v-sheet>                     
            <v-sheet v-if="currentProcess == 'SendEmail'">
                <v-form :disabled=readOnlyMode v-model="isSendEmailValid">
                <v-row>
                    <v-col class="py-3" cols="12">
                        <v-text-field :readonly=readOnlyMode hide-details v-model="sendEmailConfig.email" :rules="multiEmailRules" class="py-1 shrink" label="Email"></v-text-field>
                    </v-col>
                </v-row>                
                <v-row class="justify-end">
                    <v-btn color="blue darken-1" text @click="closeClick()">
                        Close
                    </v-btn>
                    <v-btn v-if="isSendEmailValid==true && readOnlyMode==false && sendEmailConfig.email!=''" color="blue darken-1" text  @click="saveSendEmailConfig()">
                        Save
                    </v-btn>
                </v-row>
                </v-form>
            </v-sheet>        
            <v-sheet v-if="currentProcess == 'WalletPass'">
                <KCCardBuilderView ref="cardBuilder" :readOnlyMode=readOnlyMode @canSave="(n)=> shouldShowWalletPassSaveButton = n" :headers="headers" :sampleData="sampleData" :hideCardType=true :debugMode=false :autoLoadDefault=false></KCCardBuilderView>
                <v-row class="justify-end">
                    <v-btn color="blue darken-1" text @click="closeClick()">
                        Close
                    </v-btn>
                    <v-btn v-if="shouldShowWalletPassSaveButton && readOnlyMode==false" color="blue darken-1" text  @click="saveWalletPassConfig()">
                        Save
                    </v-btn>
                </v-row>                
            </v-sheet>                                                         
            <v-sheet v-if="currentProcess == 'WalletPass (MindFire)'">
                <KCCardBuilderView ref="cardBuilder" :readOnlyMode=readOnlyMode @canSave="(n)=> shouldShowWalletPassSaveButton = n" :headers="headers" :sampleData="sampleData" :hideCardType=true :debugMode=false :autoLoadDefault=false></KCCardBuilderView>
                <v-row class="justify-end">
                    <v-btn color="blue darken-1" text @click="closeClick()">
                        Close
                    </v-btn>
                    <v-btn v-if="shouldShowWalletPassSaveButton && readOnlyMode==false" color="blue darken-1" text  @click="saveWalletPassMindFireConfig()">
                        Save
                    </v-btn>
                </v-row>                
            </v-sheet>                                                         

        </v-sheet>
    </v-dialog>
</template>
<style>
/* .kc-add-csv-process-dialog .v-input--checkbox .v-input__control {
    height: 0;
} */
.kc-add-csv-process-dialog span.required {
    color:red;    
}
</style>
<script>
// import axios from "axios";
// import { id } from "vuetify/lib/locale";
// import utils from '../services/KCUtils.js';
// import utils from "../services/KCUtils.js";
import csvUtils from "../services/KCCSVUtils.js";
import draggable from 'vuedraggable'
import KCQRCodeConfig from "./KCQRCodeConfig.vue";
import KCCardBuilderView from '../views/KCCardBuilderView.vue';
// import { consoleError } from "vuetify/lib/util/console";
// var Chiffon = require('chiffon');
var  procTitleMap = {
    "SelectColumn":"Select Column",
    "TransformColumn":"Transform Column",
    "CalculatedColumn":"Calculated Column",
    //"SortByColumn":"Sort By Column",
    "QRCode":"QRCode",
    "SendEmail":"SendEmail",
    "WalletPass":"WalletPass",
    //"WalletPass (MindFire)":"WalletPass (MindFire)",
    // "SelectTask":"Select a task from the list below",
}
var  singleTask = {
    "Select Column":"SelectColumn",
    "SendEmail":"SendEmail",
}

function getUsedColumn(str){
    var usedCols = new Set();
    const regex = /\$(\d+)|\${\$(\d+)}/gm;
    var m;
    while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        usedCols.add(parseInt(m[1]));
    }        
    //console.log("usedCols",usedCols);
    return usedCols;
}

export default {
    components: {
        draggable,
        KCQRCodeConfig,
        KCCardBuilderView
    },
    props: {
        value: {
            default: false,
        },
        headers: {
            default() {
                return []
            }
        },
        config: {
            default: {},
        },
        configItems: {
            default: {},
        },
        sampleData: {
            default() {
                return []
            }
        },
        readOnlyMode: {
            default: false,
        }
    },
    data() {
        var csvProcessList = [];
        for(var x in procTitleMap){
            csvProcessList.push(procTitleMap[x]);
        }
        return {
            dialog: this.value,
            csvProcessList: csvProcessList,
            currentProcess: null,
            reorderHeaders:[],
            orderByHeaders:[],
            selectedColumns: [],
            chkAddHeader: false,
            transformColumn: null,
            calculatedColumn: null,
            showHelp: [true,true,true],
            lock: false,
            qrCodeConfig: null,
            walletPassConfig: {},
            count: 0,
            //testCalculatedColumnValue: "",
            calculatedColumnSelectorValue: "",
            sendEmailConfig: null,
            shouldShowWalletPassSaveButton: false,
            emailRules: [ 
                v => /^\w+([.\-!#$%&'*+-/=?^{}`]?\w+)*@\w+([.-]?\w+)*(\.\w{2,100})+$/.test(v) || 'E-mail must be valid'
                //v => /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,100}$/.test(v) || 'E-mail must be valid'
            ],
            multiEmailRules: [
                value => {
                    let values = value.split(",");
                    console.log("multiEmailRules",values);
                    for(let x in values){
                        let v  = values[x].trim();
                        if(! /^\w+([.\-!#$%&'*+-/=?^{}`]?\w+)*@\w+([.-]?\w+)*(\.\w{2,100})+$/.test(v)){
                        //if(! /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,100}$/.test(v)){
                            return 'E-mail must be valid';
                        }
                    }
                    return true;
                }
            ],
            renameRules: {
                nameNotExist(_reorderHeaders,_item) {
                    var item = _item;
                    var reorderHeaders = _reorderHeaders;
                    return value => {
                        console.log(_item,value);
                        
                        var ret = true;
                        if(_item.select){
                            if(value == "") return 'Required';
                            if(typeof reorderHeaders != "undefined"){
                                //var myIndex = reorderHeaders.indexOf(item);
                                for(var x in reorderHeaders){
                                    //if(x == myIndex) continue;
                                    var tmp = reorderHeaders[x];
                                    if(item.name == tmp.name){
                                        continue;
                                    }
                                    if(tmp.select){
                                        if((value == tmp.name)||(value == tmp.rename)){
                                            ret = 'Column name exist';
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        return ret;
                    }
                },
            },
            nameRules: [
                value => {
                    return value!="" || "Required";
                },
                value => {
                    var ret = this.headers.indexOf(value)<0 || "Column exist";               
                    return ret;
                }
            ],
            valueRules: [
                value => {
                    var ret = (value!="") || "Required";
                    this.testCalculatedColumnValue = "";
                    //console.log("valueRules1",ret);
                    return ret;
                },           
                value => {
                    // check if previous rule fail
                    //if(this.valueValid == false){
                    //    return "";
                    //}
                    var ret = true;
                    this.testCalculatedColumnValue = "";
                    try{
                        value = value.trim();
                        if(this.calculatedColumn.chkEvalMode){
                            var cookedValue = csvUtils.convertColNameToColNum(value,this.headers);
                            value = csvUtils.calculatedValue(this.sampleData, cookedValue);
                        }
                        else{
                            var subCommand = csvUtils.createSubsitiueCommand(value,this.headers);
                            value = csvUtils.subsituteValue(this.sampleData,subCommand);
                        }
                        if(value==undefined){
                            value = "";
                        }                        
                    }catch(ex){
                        console.log("valueRules",ex)
                        ret = ex.message
                        value = "";
                        //this.valueValid = false;
                    }
                    //console.log("valueRules2",ret);
                    this.testCalculatedColumnValue = value;
                    return ret;
                }                     
            ],
            transformColumnNameRules: [
                value => {
                    var ret = value!="" || "Required";
                    return ret;
                },
            ],
            transFormValueRules: [
                value => {
                    var ret=  value!="" || "Required";
                    this.testTransformColumnValue = "";
                    return ret;
                },
                value => {
                    // check if previous rule fail
                    var ret = true;
                    this.testTransformColumnValue = "";
                    try{
                        value = value.trim();
                        var column = this.headers.indexOf(this.transformColumn.column);
                        if(this.transformColumn.chkEvalMode){
                            value = csvUtils.calculatedValue(this.sampleData, value,true,this.sampleData[column]);                            
                        }else{
                            //check {} to correct header 
                            value = value.replaceAll("{}",`{${this.headers[column]}}`);
                            var subCommand = csvUtils.createSubsitiueCommand(value,this.headers);
                            value = csvUtils.subsituteValue(this.sampleData,subCommand);
                        }
                    }catch(ex){
                        console.log("valueRules",ex.message)
                        ret = ex.message
                    }
                    this.testTransformColumnValue = value;
                    //console.log("valueRules2",ret);
                    return ret;
                }  
            ],
            isSendEmailValid: false,
            isSelectFormValid: true,
            //valueValid: true,
            isCalculatedColumnValid: false,
            testCalculatedColumnValue: "",

            isTransformColumnValid: false,
            testTransformColumnValue: "",
        };
    },
    created() {
        this.init();
    },
    methods: {
        // Init default value
        
        init(){
            var defaultCSVProcIndex = 5;
            //this.currentProcess = this.csvProcessList[defaultCSVProcIndex];
            //if(this.headerHasID()==false){  // no _id
                this.currentProcess = "";
            //}
            console.log("init","config=",this.config);
            this.accountID = this.$store.getters.user.account,
            this.email = this.$store.getters.user.email,
           
            this.selectedColumns = [... this.headers];
            this.chkAddHeader = false;
            this.reorderHeaders = [];
            this.orderByHeaders = [];
            this.qrCodeConfig =  {
                name: "",
                value: "",
                ignoreEmpty: true,
                err: "",
                defaultOption: {
                    margin: "0",
                    color: {
                        dark: "#000000FF",
                        light: "#F1F1F1FF",
                        lefttop: "#000000FF",
                        righttop: "#000000FF",
                        leftbottom: "#000000FF",
                        svgLogo: "#000000FF",
                        // lefttopeye: "#fa6e79FF",
                        // righttopeye: "#00bfffFF",
                        // leftbottomeye: "#2d7cdaFF",
                        frame: "#000000FF",
                        frameText: "#FFFFFFFF",
                    },
                    cellMode: "square",
                    width: 100,
                    frame: "",
                    frameText: "Hello World",
                    svgLogo: "",
                    logo: "",
                },      
                chkGenImage: false,
                chkRoundEdge: false,
                chkGrayScale: true,
                logoLocalFileName: "",
                imageFormat: "jpg",
                imageColumn: "",
                chkCreateNewColumn: true,
                chkEvalMode: false,
                columnName: "",
            },
            this.transformColumn = {
                column: "",
                value: "",
                ignoreEmpty: true,
                //err:"",
                chkEvalMode: false,
            },
            this.calculatedColumn =  {
                name: "",
                value: "",
                ignoreEmpty: true,
                //err:"",
                chkEvalMode: false,
            }
            this.lock = false;
            this.calculatedColumnSelectorValue = "";
            this.sendEmailConfig = {
                email: this.email,
            },
            this.isSendEmailValid=false;
        },
        // canSaveTransformColumn(){
        //     if(this.transformColumn.column.trim()==""){
        //         return false;
        //     }
        //     var value = this.transformColumn.value.trim();
        //     if(value == ""){
        //         return false;
        //     }
        //     var column = this.headers.indexOf(this.transformColumn.column);
        //     if(column<0){
        //         return false;
        //     }
        //     try{
        //         //this.transformColumn.err = "";
        //         value = csvUtils.calculatedValue(this.sampleData, value,true,this.sampleData[column]);
        //         if(value==undefined){
        //              value = "";
        //         }
        //         //this.testTransformColumnValue = value;
        //     //     return true;
        //     }catch(ex){
        //         //this.transformColumn.err = ex.message;
        //         return false;
        //     }
        //     return true;
        // },
        saveTransformColumn(){
            var column = this.headers.indexOf(this.transformColumn.column);
            if(column >=0){
                //var ret = this.isValidScript(this.transformColumn.value);
                //if(ret == true){
                    this.dialog = false;
                    this.$emit("commit",["TransformColumn",{... this.transformColumn}]);
                //}else{
                //    console.log(ret);
                //}
            }
        },
        selectColumnCount(){
            var arr = this.reorderHeaders.filter(obj =>{
                return obj.select == true;
            })
            return arr.length;
        },
        saveSelectColumn(){
            this.$emit("commit",["SelectColumn",this.reorderHeaders]);
            this.dialog = false;
        },
        saveCalculatedColumn(){
            //this.calculatedColumn.err = "";
            //var ret = this.isValidScript(this.calculatedColumn.value);
            //if(ret == true){
                this.dialog = false;
                this.$emit("commit",["CalculatedColumn",this.calculatedColumn]);
            //}else{
            //    console.log(ret);
                //this.calculatedColumn.err = ret;
            //}
        },
        sortByColumnCount(){
            var arr = this.orderByHeaders.filter(obj =>{
                return obj.select == true;
            })
            return arr.length;
        },
        saveSortByColumn(){
            this.$emit("commit",["SortByColumn",this.orderByHeaders]);
            this.dialog = false;
        },
        saveQRCodeConfig(){
            this.$emit("commit",["QRCode",this.qrCodeConfig]);
            this.dialog = false;
        },
        saveSendEmailConfig(){
            this.$emit("commit",["SendEmail",this.sendEmailConfig]);
            this.dialog = false;
        },
        async saveWalletPassConfig(){
            //var cardData = this.$refs.cardBuilder.getCardData();
            this.$swal.fire({
                title: 'Saving pass',
                html: `<span></span>`,
            });
            this.$swal.showLoading();
            var saveRet = await this.$refs.cardBuilder.saveClick();
            console.log(saveRet);
            this.$swal.close();
            if(saveRet.data.result.insertId){
                this.walletPassConfig.passID = saveRet.data.result.insertId;
            }
            // clear copy flag if have
            delete this.walletPassConfig.copy;
            this.walletPassConfig.templateData = this.$refs.cardBuilder.getCardData().resource.templateData;
            this.$emit("commit",["WalletPass",this.walletPassConfig]);
            this.dialog = false;
        },
        
        async saveWalletPassMindFireConfig(){
            //var cardData = this.$refs.cardBuilder.getCardData();
            this.$swal.fire({
                title: 'Saving pass',
                html: `<span></span>`,
            });
            this.$swal.showLoading();
            var saveRet = await this.$refs.cardBuilder.saveClick();
            console.log(saveRet);
            this.$swal.close();
            if(saveRet.data.result.insertId){
                this.walletPassConfig.passID = saveRet.data.result.insertId;
            }
            // clear copy flag if have
            delete this.walletPassConfig.copy;
            this.walletPassConfig.templateData = this.$refs.cardBuilder.getCardData().resource.templateData;
            this.$emit("commit",["WalletPass (MindFire)",this.walletPassConfig]);
            this.dialog = false;
        },
        // isValidScript(script){
        //     var usedCols = getUsedColumn(script);
        //     for(var x of usedCols){
        //         if(x-1 >= this.headers.length){
        //             return `Invalud column ${x}`
        //         }
        //     }
        //     return true;
        // },
        processConfig(){
            this.init();
            //this.columnName = [... this.headers];
            //reorderColumn
            //this.columnHeaders = [... this.headers];
            var x;
            console.log("processConfig",this.config);
            switch(this.config[0]){
                case "TransformColumn":{
                    this.testTransformColumnValue = "";
                    this.currentProcess = procTitleMap[this.config[0]];
                    this.transformColumn = {... this.config[1]};
                    this.lock = true;
                    if("formTransformColumn" in this.$refs){
                        this.$refs.formTransformColumn.validate();
                    }
                }break;
                case "CalculatedColumn":{
                    this.testCalculatedColumnValue = "";
                    this.currentProcess = procTitleMap[this.config[0]];
                    this.calculatedColumn = {... this.config[1]};
                    this.lock = true;
                    if("formCalculatedColumn" in this.$refs){
                        this.$refs.formCalculatedColumn.validate();
                    }
                }break;
                case "SelectColumn":{
                    this.currentProcess = procTitleMap[this.config[0]];
                    this.reorderHeaders = [];
                    var reorderHeaders = this.config[1];
                    for(x in reorderHeaders){
                        //if(this.headers.indexOf(reorderHeaders[x].name)>=0){
                        if(csvUtils.getColumnIndex(this.headers,reorderHeaders[x].name)>=0){
                            this.reorderHeaders.push(reorderHeaders[x]);
                        }
                    }
                    // this.reorderHeaders = JSON.parse(JSON.stringify(this.config[1]));
                    // // filter found in header
                    // this.reorderHeaders = this.reorderHeaders.filter(obj =>{
                    //     return this.headers.indexOf(obj.name) >= 0
                    // })
                    for(x in this.reorderHeaders){
                        if(this.reorderHeaders[x].name != this.reorderHeaders[x].rename){
                            this.chkAddHeader = true;
                            break
                        }
                    }
                    this.lock = true;
                }break;
                case "SortByColumn":{
                    this.currentProcess = procTitleMap[this.config[0]];
                    this.orderByHeaders = [... this.config[1]];
                    // filter found in header
                    this.orderByHeaders = this.orderByHeaders.filter(obj =>{
                        return this.headers.indexOf(obj.name) >= 0
                    })                    
                    this.lock = true;
                }break;
                case "QRCode":{
                    this.currentProcess = procTitleMap[this.config[0]];
                    console.log("this.qrCodeConfig",this.qrCodeConfig);
                    this.qrCodeConfig = {... this.config[1]};
                    console.log("this.qrCodeConfig",this.qrCodeConfig);
                    this.lock = true;
                }break
                case "SendEmail": {
                    this.currentProcess = procTitleMap[this.config[0]];
                    this.sendEmailConfig = {... this.config[1]};
                    this.lock = true;
                }break;
                case "WalletPass":{
                    this.currentProcess = procTitleMap[this.config[0]];
                    this.walletPassConfig = {... this.config[1]};
                    this.lock = true;
                    this.$nextTick(() => {
                        this.$refs.cardBuilder.loadByID(this.walletPassConfig.passID,this.walletPassConfig.copy);
                    });
                }break;
                default: {
                    this.$nextTick(() => {
                        if(this.$refs.cardBuilder){
                            this.$refs.cardBuilder.loadByDefault();
                        }
                    });
                }
            }
            // merge with default from header incase header might has more column
            for(x in this.headers){
                var result = this.reorderHeaders.filter(obj => {
                    return obj.name === this.headers[x]
                })
                if(result.length == 0){
                    this.reorderHeaders.push({
                        name: this.headers[x],
                        rename: this.headers[x],
                        select: true,
                    });
                }
                result = this.orderByHeaders.filter(obj => {
                    return obj.name === this.headers[x]
                })
                if(result.length == 0){
                    this.orderByHeaders.push({
                        name: this.headers[x],
                        select: false,
                        ascending: true,
                    })
                }

            }
            this.startConfig = {
                calculatedColumn: {... this.calculatedColumn},
                qrCodeConfig: {... this.qrCodeConfig},
                reorderHeaders: JSON.parse(JSON.stringify(this.reorderHeaders)),
                transformColumn: {... this.transformColumn},
                orderByHeaders: JSON.parse(JSON.stringify(this.orderByHeaders)),
                sendEmailConfig: {... this.sendEmailConfig},
            }            
            if(this.startConfig.qrCodeConfig.defaultOption.logo == ""){
                delete this.startConfig.qrCodeConfig.defaultOption.logo;
            }
            //console.log("this.startConfig",this.startConfig);
        },
        helpClick(n){
            //array change caveats.
            this.$set(this.showHelp, n, !this.showHelp[n]);
            console.log(this.showHelp);
        },
        isDirty(){
            switch(this.currentProcess){
                case "Select Column":{
                    // console.log(JSON.stringify(this.startConfig.reorderHeaders));
                    // console.log(JSON.stringify(this.reorderHeaders));
                    return  (JSON.stringify(this.startConfig.reorderHeaders)!=JSON.stringify(this.reorderHeaders));
                }
                case "Transform Column":{
                    return  (JSON.stringify(this.startConfig.transformColumn)!=JSON.stringify(this.transformColumn));
                }
                case "Sort By Column":{
                    return  (JSON.stringify(this.startConfig.orderByHeaders)!=JSON.stringify(this.orderByHeaders));
                }
                case "Calculated Column":{
                    return  (JSON.stringify(this.startConfig.calculatedColumn)!=JSON.stringify(this.calculatedColumn));
                }
                case "QRCode":{
                    // error is not count as dirty
                    var x1 = {... this.startConfig.qrCodeConfig}; delete x1.err;
                    var x2 = {... this.qrCodeConfig}; delete x2.err;
                    return  (JSON.stringify(x1)!=JSON.stringify(x2));
                }
                case "SendEmail":{
                    return  (JSON.stringify(this.startConfig.sendEmailConfig)!=JSON.stringify(this.sendEmailConfig));
                }
                case "WalletPass":{
                    return false;
                }

            }
            return false;
        },
        async closeClick(){
            if(this.isDirty() && (this.readOnlyMode == false)){
                // console.log("startConfig.qrCodeConfig",JSON.stringify(this.startConfig.qrCodeConfig));
                // console.log("this.qrCodeConfig",JSON.stringify(this.qrCodeConfig));
                // if(JSON.stringify(this.startConfig.qrCodeConfig)==JSON.stringify(this.qrCodeConfig)){
                //     this.dialog = false;
                //     return;
                // }
                var result = await this.$swal.fire({
                    title: 'Are you sure you want to close this function without saving your work?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    // confirmButtonColor: '#3085d6',
                    // cancelButtonColor: '#d33',
                    // confirmButtonText: 'Yes',
                    // cancelButtonText: 'No',
                    ... csvUtils.getSwalYesNoOption()
                });
                console.log(result);
                if (result.isConfirmed) {
                    // await this.deleteTaskStatus(Id);
                    // await this.loadTaskStatus();
                    this.dialog = false;
                }        
            }else{
                this.dialog = false;
            }    
        },
        isInConfig(name){
            for(let x in this.configItems){
                if(name == this.configItems[x].process){
                    return true;
                }
            }
            return false;
        },
        headerHasID(){
            if(this.headers.indexOf("_id")>=0){
                return true;
            }
            return false;
        },
    },
    computed: {
        taskLabel(){
            if(this.currentProcess == ""){
                return "Select a task from the list below";
            }else{
                return "Task";
            }
        },
        myWidth(){
            if(this.currentProcess == "WalletPass"){
                return 1100;
            }
            if(this.currentProcess == "WalletPass (MindFire)"){
                return 1100;
            }

            return 800;
        },
        effectiveCsvProcessList(){
            let result = [];
            //let singleTaskList = ["SendEmail","Select Column"]
            for(let x in this.csvProcessList){
                var procName = this.csvProcessList[x];
                var customDisabled = false;
                if(procName in singleTask){
                //if(singleTaskList.indexOf(procName) >= 0){
                    if(this.isInConfig(singleTask[procName])){
                        customDisabled = true;
                    }
                }
                if (procName == "WalletPass"){
                    if(this.headerHasID()==false){
                        customDisabled = true;
                    }
                }
                result.push({
                    text: procName, value: procName, customDisabled: customDisabled,
                });
            }   
            console.log("effectiveCsvProcessList",result);
            return result;
        },
        // isValidCalculatedColumnScript(){
        //     return this.isValidScript(this.calculatedColumn.value);
        // },
        shouldShowQrcodeSaveButton(){
            console.log("shouldShowQrcodeSaveButton",{ ... this.qrCodeConfig});
            if(this.readOnlyMode == true || this.qrCodeConfig.err !="" || (this.qrCodeConfig.chkGenImage == true && this.qrCodeConfig.imageColumn == "")){
                console.log(1);
                return false;
            }
            if(this.qrCodeConfig.chkCreateNewColumn){
                if(this.qrCodeConfig.name=='' || this.qrCodeConfig.value==''){
                    console.log(3);
                    return false;
                }
            }else{
                if(this.qrCodeConfig.columnName == ""){
                    console.log(2);
                    return false;
                }
            }
            return true;
        },

    },
    watch: {
        value(newValue, oldValue) {
            console.log("value change",newValue,this.config,this.headers,this.sampleData);
            this.dialog = newValue;
            //On show
            if(newValue == true){
                this.processConfig();
            }
        },
        calculatedColumnSelectorValue(newValue, oldValue) {
            if(newValue != ""){
                // var elInput = this.$refs.calculatedColumnValue;
                // console.log(this.$refs.calculatedColumnValue);
                // var startPos = elInput.selectionStart;
                // var endPos = elInput.selectionEnd;
                // console.log(startPos,endPos);
                if(this.calculatedColumn.chkEvalMode){
                    this.calculatedColumn.value += '$["' + newValue +'"]';
                }else{
                    this.calculatedColumn.value += '{' + newValue +'}';
                }
            }
        },
        dialog(newValue, oldValue) {
            this.$emit("input", newValue); // v-model need this to tell parent that value have change
        },
        headers(newValue, oldValue) {

        },
        config(newValue,oldValue){
        },
        "calculatedColumn.chkEvalMode":  function (...args) {
            if(args[0]!=null){
                if("formCalculatedColumn" in this.$refs){
                    this.$refs.formCalculatedColumn.validate();
                }
            }
        },
        "transformColumn.chkEvalMode":  function (...args) {
            if(args[0]!=null){
                if("formTransformColumn" in this.$refs){
                    this.$refs.formTransformColumn.validate();
                }
            }
        },
        chkAddHeader(newValue,oldValue){
            if(newValue == false){
                for(var x in this.reorderHeaders){
                    this.reorderHeaders[x].rename = this.reorderHeaders[x].name;
                }
            }
        },
        currentProcess(newValue,oldValue){
            console.log("change to " + newValue);
            console.log(this.config);
            if(newValue == "WalletPass"){
                this.$nextTick(() => {
                    this.$refs.cardBuilder.loadByDefault();                        
                });
            }
            if(newValue == "WalletPass (MindFire)"){
                this.$nextTick(() => {
                    this.$refs.cardBuilder.loadByDefault("mindfire");                        
                });
            }
            
        }
    },
}
</script>